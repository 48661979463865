import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import ProductShortDescription from './partials/ProductShortDescription';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'We are ready for the next step',
    paragraph: 'Mithikǝl has self-financed research, design and development of specialized technologies in mission-critical and high availability systems. MattDB is one of the technological tools obtained from the effort of more than 20 years of hard work. ADITI is our main project, it is currently in the 3.0.0 version. It is the evolution of a platform that we previously developed and where critical financial systems are operated for a very important institution in Mexico. This is the only platform in the world for highly decentralized systems without using orchestrators (coordinators), it was specifically designed to manage mission-critical systems with requirements for high availability, real-time, online maintenance, among other characteristics. It uses MattDB as its database manager. The technologies developed by Mithikǝl are ready to be commercialized. We are looking for one of the biggest software companies in the world that creates infrastructure technology to show our technological potential by their side.'
  };

  const sectionHeaderOurInnovations = {
    title: 'Our innovations',
    paragraph: ''
  };

  return (
    <section id={'next-step'}
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h4 id={'products'} className='center-content'>
          <SectionHeader data={sectionHeaderOurInnovations} className="center-content" />
          </h4>
          <div className={tilesClasses}>
            <ProductShortDescription
              title='Aditi platform'
              text='It is the only platform in the world for highly decentralized systems without using orchestrators (coordinators), it was specifically designed to manage mission-critical systems with requirements for high availability, real-time, online maintenance, among other characteristics'
              imageRoute={require('./../../assets/images/logo-aditi.svg')}
              width={150}
              height={150}
            ></ProductShortDescription>
            <ProductShortDescription
              title='MattDB'
              text='It is an embedded key-value database, object-oriented, high-performance, ACID transactional and agile development database manager, it is written entirely in Java'
              imageRoute={require('./../../assets/images/logo-mattdb.svg')}
              width={150}
              height={150}
            ></ProductShortDescription>
          </div>
          <div className={tilesClasses}>
            <ProductShortDescription
              title='Serializer'
              text='It is high performance library for translating Java Objects into byte arrays and vice versa, reduce the execution time and the array bytes size'
              imageRoute={require('./../../assets/images/logo-serializer.svg')}
              width={150}
              height={150}
            ></ProductShortDescription>
            <ProductShortDescription
              title='Parallels'
              text='It is a library to manage the Java threads'
              imageRoute={require('./../../assets/images/logo-parallels.svg')}
              width={150}
              height={150}
            ></ProductShortDescription>
            <ProductShortDescription
              title='Network'
              text='It is a library that simplifies the sending and receiving of data packages through sockets'
              imageRoute={require('./../../assets/images/logo-network.svg')}
              width={150}
              height={150}
            ></ProductShortDescription>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;