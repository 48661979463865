import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import ShowChart from "../elements/charts/ShowChart";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesMattDBvsBerkeleyDB = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Comparison, MattDB against BerkeleyDB",
    paragraph:
      "The execution time, the storage size and the memory consumed was measured when processing 100,000 transactions on a database model comprised of 20 tables with different data types (Integer, Short, String, Character, byte [], Long, Double, etc). Each transaction had from 1 to 20 random movements. A movement corresponds to a data reading, updating or deleting.",
  };

  const green = "#329920";
  const yellow = "#999320";
  const red = "#992020";

  const dataTimeEnsureAcid = [
    ["Data Bases", "", { role: "style" }],
    ["MattDB-BGTech", 0.95, `${green}`],
    ["MattDB-Simple", 4.51, `${yellow}`],
    ["BerkeleyDB-Sync", 25.86, `${red}`],
  ];

  const dataTimeNotEnsureAcid = [
    ["Data Bases", "", { role: "style" }],
    ["MattDB-OM", 5.77, `${green}`],
    ["BerkeleyDB-NoSync", 9.12, `${red}`],
  ];
  const dataMemory = [
    ["Data Bases", "", { role: "style" }],
    ["MattDB-BGTech", 114.2, `${green}`],
    ["MattDB-Simple", 140.1, `${green}`],
    ["BerkeleyDB-Sync", 381.1, `${red}`],
    ["MattDB-OM", 112, `${green}`],
    ["BerkeleyDB-NoSync", 439.1, `${red}`],
  ];
  const dataStorage = [
    ["Data Bases", "", { role: "style" }],
    ["MattDB-BGTech", 273.5, `${green}`],
    ["MattDB-Simple", 273.5, `${green}`],
    ["BerkeleyDB-Sync", 320.73, `${red}`],
    ["MattDB-OM", 273.5, `${green}`],
    ["BerkeleyDB-NoSync", 319.72, `${red}`],
  ];


  return (
    <section id={"comparison"} {...props} className={outerClasses}>
      <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content" />
        <div className="center-content text-color-error container-sm">
          <ShowChart
            // width={'1000px'}
            height={"300px"}
            mainTitle="Time in minutes"
            hAxisTitle="Minutes"
            vAxisTitle="Ensures ACID"
            data={dataTimeEnsureAcid}
            // hAxisMin = '0'
            // hAxisMax = '30'
          ></ShowChart>
          <p>MattDB was 27.2 times faster than BerkeleyDB</p>
          <ShowChart
            // width={'1000px'}
            height={"200px"}
            mainTitle="Time in seconds"
            hAxisTitle="Seconds"
            vAxisTitle="Not Ensures ACID"
            data={dataTimeNotEnsureAcid}
            // hAxisMin='0'
            // hAxisMax = '30'
          ></ShowChart>
          <p>MattDB was 1.5 times faster than BerkeleyDB</p>
          <ShowChart
            // width={'1000px'}
            height={"500px"}
            mainTitle="Memory"
            hAxisTitle="MB"
            vAxisTitle="Not Ensures ACID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ensures ACID"
            data={dataMemory}
            // hAxisMin='0'
            // hAxisMax = '450'
          ></ShowChart>
          <p>MattDB used 266.90 MB fewer</p>
          <ShowChart
            // width={'1000px'}
            height={"500px"}
            mainTitle="Storage"
            hAxisTitle="MB"
            vAxisTitle="Not Ensures ACID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ensures ACID"
            data={dataStorage}
            // hAxisMin = '0'
            // hAxisMax = '450'
          ></ShowChart>
          <p>MattDB used 47.68 MB fewer</p>
        </div>
      </div>
    </section>
  );
};

FeaturesMattDBvsBerkeleyDB.propTypes = propTypes;
FeaturesMattDBvsBerkeleyDB.defaultProps = defaultProps;

export default FeaturesMattDBvsBerkeleyDB;
