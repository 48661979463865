import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import CharacteristicDescription from './partials/CharacteristicDescription';



const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplitMattDB = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'MattDB, transacctions like no other database',
    paragraph: 'MattDB is an embedded key-value database, object-oriented, high-performance, ACID transactional and agile development database manager. It is written entirely in Java.'
  };

  return (
    <section id='mattdb-features'
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <CharacteristicDescription
              titleLightning='Multiple Databases'
              title='Data Access'
              text='Allows managing multiple databases from a single MattDB instance'
              image='dataaccess.svg'
            ></CharacteristicDescription>
            <CharacteristicDescription
              titleLightning='Transactions at memory speed'
              title='Only Memory Technology'
              text='If the milliseconds are important in your business, MattDB could execute all the transactions at memory speed'
              image='only-memory.svg'
            ></CharacteristicDescription>
            <CharacteristicDescription
              titleLightning='Locks'
              title='Secure Transaction'
              text='Controls database locks and allows to execute actions after closing the transaction'
              image='secure-transaction.svg'
            ></CharacteristicDescription>
            <CharacteristicDescription
              titleLightning='Backups'
              title='Hot snapshots'
              text='Generates hot snapshots of the database and load them without stopping'
              image='snapshot.svg'
            ></CharacteristicDescription>
            <CharacteristicDescription
              titleLightning='Improve transactions'
              title='Background Transaction'
              text='It considerably reduces the execution time of transactions, fulfilling all the ACID properties'
              image='backgroud-transaction.svg'
            ></CharacteristicDescription>
            <CharacteristicDescription
              titleLightning='Agile development'
              title='Agile development'
              text='MattDB was designed to (i)generate the database models in a few steps, and (ii)be able to mutate them even when already running'
              image='agile-develpment.svg'
            ></CharacteristicDescription>
            <CharacteristicDescription
              titleLightning='Improve searches'
              title='Secondary Key'
              text='Optimizes searches on the database'
              image='secondary-key.svg'
            ></CharacteristicDescription>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplitMattDB.propTypes = propTypes;
FeaturesSplitMattDB.defaultProps = defaultProps;

export default FeaturesSplitMattDB;