import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import CharacteristicDescription from "./partials/CharacteristicDescription";
import Features from "./partials/Features";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplitAditi = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Autonomous Decentralized IT Infrastructure - ADITI",
    paragraph:
      "The systems that run on ADITI increase its survival level: downtime risk is reduced in case of external failures, such as memory, data storage or CPU problems. Also, in the case of the loss of a server or network failures, operational continuity can be achieved. ADITI is prepared for the evolution of the requirements. It was designed for the interconnected world of tomorrow, where stopping a moment will be much more expensive than is now.",
  };

  return (
    <section id="aditi" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <CharacteristicDescription
              titleLightning="Main component"
              title="Cell"
              text="Cell is the software component that provides microservices to other of its same type, 
              in applications developed with Aditi technology. It is inspired by the functioning of living organisms. 
              The functionality of an Aditi application takes place in the Aditi-Cell core: the internal
              logic of each microservice is implemented into the DNA of the component.
              
              It also contains a communication module to enable the sending and receiving service requests through Aditi-Net. 
              Aditi-Cell uses a high-performance object-oriented database called 
              MattDB that allows the component to persist the critical information of the stateless microservices. 
              Nonetheless, Aditi-Cell is able to manage external resources such as Hibernate, web services, DBMS, among others, using
              the add-ons built in the Aditi infrastructure.
              
              Underneath all these features, Aditi-Cell deals with information security, providing an integrated mechanism for deploying different
              protection levels in the sending of information and authentication mechanisms between the components of Aditi infrastructure."
              image="logo-cell.svg"
              features={
                <Features
                  features={[
                    "High performance",
                    "High functional availability",
                    "Online maintenance and updates",
                    "Maximum efficiency in the use of computational resources",
                    "Parallel execution of microservices inside cell components",
                    "Transactional handling of the data persistence",
                    "Priority levels in the execution of microservices",
                    "Self-recovery in case of failure",
                    "Reduction of development times for specific functionalities and elimination of regression testing",
                    "Selective security mechanisms",
                    "Integration with other technologies",
                  ]}
                />
              }
            ></CharacteristicDescription>

            <CharacteristicDescription
              titleLightning="Middleware"
              title="Bloodstream-Node"
              text="Bloodstream-Node is the infrastructure that allows the sending and receiving of messages between 
              distributed applications developed with Aditi technology. Aditi-Net is inspired by the endocrine 
              communication of the living cells, where the messages are transmitted through  hormones poured into 
              the bloodstream. Once in the blood, hormones circulate throughout the body, being taken only by the 
              cells that must receive the message. Aditi-Net is the technological analog of the bloodstream."
              image="logo-node.svg"
              features={
                <Features
                  features={[
                    "High reliability",
                    "High availability",
                    "Asynchronous communication",
                    "High elasticity",
                    "Bi-modal communication (point-to-point and publish-subscriber)",
                    "Timeliness comunication (2 steps)",
                    "Secure authentication (3 different mechanisms)",
                  ]}
                />
              }
            ></CharacteristicDescription>

            <CharacteristicDescription
              titleLightning="Manager"
              title="Cellman"
              text="Cellman is the component that allows controlling the Aditi platform. 
              Through it, it is possible to model Aditi-Cells; to manage microservices and computational resources 
              (installing, uninstalling, monitoring); to put online systems built with Aditi technology and tostablish 
              their security levels and mechanisms. Like the other components of the platform, its design is completely 
              decentralized. Aditi-Manager consists of three software elements that are independent of each other but 
              work collaboratively: a client or graphic user interface to indicate the actions of the platform; a server, 
              where the information of the Aditi-Cells, systems and microservices is stored and managed;an agent that gives 
              direct access to computational resources for the platform."
              image="logo-cellman.svg"
              features={
                <Features
                  features={[
                    "Decentralization of control services",
                    "High availability of intent components",
                    "Control and monitoring of computational resources",
                    "Creación e instalación de instancias de Aditi-Cell",
                    "Modificación del ADN de las Aditi -Cell",
                    "Built with Aditi technology",
                  ]}
                />
              }
            ></CharacteristicDescription>

            <CharacteristicDescription
              titleLightning="Ready to do more"
              title="Cell add ons"
              text="Adhered to the ideals of ADITI, we want to facilitate the work of those who develop in our technology, 
              so that they focus in exploiting the potential that ADITI offers and do not worry about how to integrate basic 
              technologies to their applications. For this reason, a group of add-ons that allow a better way of collaborating 
              between ADITI and external resources has been included in the platform.
              The first series of add-ons focuses on database management, with complements for Hibernate and DBMS. In addition to an add-on for web services."
              image="logo-addons.svg"
              features={
                <Features
                  features={[
                    "Compatibility assured with ADITI",
                    "Time saving during the development of ADITI applications",
                  ]}
                />
              }
            ></CharacteristicDescription>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplitAditi.propTypes = propTypes;
FeaturesSplitAditi.defaultProps = defaultProps;

export default FeaturesSplitAditi;
