import React from "react";

const Features = ({ features }) => {
  return (
    <div className="reveal-from-right" data-reveal-delay="200">
      <span className="testimonial-item-link">
        <a href="#0">Features</a>
      </span>
      {features.map((feature, i) => {
        return <p key={feature} className="text-sm mb-0">— {feature}</p>;
      })}

      <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider"></div>
    </div>
  );
};

export default Features;
