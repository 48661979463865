import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplitMattDB from '../components/sections/FeaturesSplitMattDB';
import FeaturesMattDBvsBerkeleyDB from '../components/sections/FeaturesMattDBvsBerkeleyDB';
import FeaturesSplitAditi from '../components/sections/FeaturesSplitAditi';

const Home = () => {

  return (
    <>
      <Hero  />
      <FeaturesTiles />
      <FeaturesSplitAditi invertMobile topDivider imageFill className="illustration-section-01" />
      <FeaturesSplitMattDB invertMobile topDivider imageFill className="illustration-section-01" />
      <FeaturesMattDBvsBerkeleyDB invertMobile topDivider imageFill className="illustration-section-01" />
    </>
  );
}

export default Home;