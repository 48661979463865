import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import animateScrollTo from 'animated-scroll-to';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  let width = props.width;
  let height = props.height;
  if (!width || !height) {
    width = 100;
    height = 100;
  }

  const goHome = () => {
    animateScrollTo(0);
  }

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to='#home'>
          <Image
            src={require('./../../../assets/images/logo-mithikel.svg')}
            alt="Open"
            width={width}
            height={height}
            onClick={goHome}
          />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;