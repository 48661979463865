import React from 'react';
import Chart from "react-google-charts";

const ShowChart = ({ mainTitle, hAxisTitle, vAxisTitle, data, width, height, hAxisMin, hAxisMax }) => {

    return (
        <div className="plit-item-content center-content-mobile reveal-from-right" data-reveal-delay={200}>
            <Chart
                width={width}
                height={height}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    title: mainTitle,
                    chartArea: { width: '80%' },
                    hAxis: {
                        legendTextStyle: 'none',
                        title: hAxisTitle,
                        textStyle: {
                            color: '#fff',
                        },
                        titleTextStyle: {
                            color: '#fff',
                            italic: 'false'
                        },
                        viewWindow: {
                            min: `${hAxisMin}`,
                            max:`${hAxisMax}`
                        },
                    },
                    vAxis: {
                        title: vAxisTitle,
                        textStyle: {
                            color: '#fff',
                        },
                        titleTextStyle: {
                            color: '#fff',
                            italic: 'false'
                        }
                    },
                    colors: ['red', 'green'],
                    is3D: true,
                    backgroundColor: { fill: 'transparent' },
                    legendTextStyle: { color: '#fff' },
                    titleTextStyle: { color: '#fff', fontSize: '25' },

                    legend: { position: 'none' }
                }}

            />
        </div>
    );
}

export default ShowChart;