import React from 'react';
import classNames from 'classnames';
import Image from '../../elements/Image';


const CharacteristicDescription = ({ titleLightning, title, text, image, features }) => {

    return (
        <div className="split-item">
            <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-0">
                    {titleLightning}
                </div>
                <h3 className="mt-0 mb-12">
                    {title}
                </h3>
                <p className="m-0">
                    {text}
                </p>
            </div>
            <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require(`./../../../assets/images/${image}`)}
                    width={528}
                    height={396} />
                    {features}
            </div>
            
        </div>
        
    );
}

export default CharacteristicDescription;